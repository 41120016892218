import Vue from 'vue'
import App from './App.vue'
import router from './router/router.js'
import Antd from 'ant-design-vue';
import $ from "jquery";
import "@/assets/js/rem.js"
import "@/assets/css/reset.css"
import "@/assets/css/main.css"
import 'ant-design-vue/dist/antd.less';
import utils from './utils'
import toHistory from './components/toHistory.vue';  
  
Vue.component('to-history', toHistory);
Vue.prototype.$utils = utils
Vue.config.productionTip = false;

const wx = window.wx;
Vue.prototype.$wx = wx;

Vue.use(Antd);
Vue.use($);

new Vue({
    render: h => h(App),
    router,
    data: function() {
        return {
            baseUrl: "/scrm_pc",
            // url: "http://scrmapp.jctmj.cn/index.php",/////
            url: "http://scrm.3dian.store/index.php",/////
            // url: "http://sdcrmys.jctmj.cn/index.php",/////
            
            // url: "http://jszccrm2.jctmj.cn/index.php",/////
            // url: "http://scrm.loca.cn/index.php"
            // url: "http://jb2.scrm.cn/index.php"
            //source ~/.bash_profile
            //nvm use 14.21.3 
        }
    },
    metods: {

    }
}).$mount('#app')