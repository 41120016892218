// import Vue from 'vue'
// import Router from 'vue-router'
// // import Index from '../pages/scrm_pc/index'
// import Index2 from '../pages/scrm_wap/index2'
// // import UserInfo from '../pages/userInfo'

// Vue.use(Router)

// export default new Router({
//     routes: [

//         {
//             path: '/index2',
//             name: 'index2',
//             component: Index2,

//         },
//         {
//             path: '/scrm_pc/staffList',
//             name: 'scrm_pc/staffList',
//             component: () => import( '../pages/scrm_pc/staffList')
//         },
//         {
//             path: '/scrm_wap/staffList',
//             name: 'scrm_wap/staffList',
//             component: () => import( '../pages/scrm_wap/staffList')
//         },
//         {
//             path: '/scrm_wap/addClient',
//             name: 'scrm_wap/addClient',
//             component: () => import( '../pages/scrm_wap/addClient')
//         },
//         // {
//         //     path: '/userCenter',
//         //     name: 'userCenter',
//         //     component: UserCenter,
//         //     children: [
//         //         {
//         //             path: 'userInfo',
//         //             name: 'userInfo',
//         //             component: UserInfo
//         //         }
//         //     ]
//         // }
//         // {
//         //     path: '/',
//         //     redirect: '/home'
//         // },
//         {
//             path: '/',
//             component: () => import('../pages/scrm_pc/test1/test1-1.vue'),
//             meta: {
//                 title: '整体页面布局'
//             },
//             children: [
//                 {
//                     path: '/home',
//                     component: () => import('../pages/scrm_pc/home.vue'),
//                     meta: {
//                         title: '首页'
//                     },
//                     // redirect:'/home',     // 该配置是若点击选择父目录时，默认选中该父目录下的子路径页面
//                     //     children: [{
//                     //         path: '/home',
//                     //         component: () => import('../pages/scrm_pc/home.vue'),
//                     //         meta: {
//                     //             title: '首页'
//                     //         },
//                     //     }]
//                 },
//                 {
//                     path: '/test1',
//                     component: () => import('../pages/scrm_pc/test1/index.vue'),
//                     meta: {
//                         title: '一级菜单1'
//                     },
//                     redirect:'/test1/test1-1',     // 该配置是若点击选择一级菜单时，默认选中并跳转到该一级菜单下的第一个二级菜单
//                         children:[
//                             {
//                                 path: 'test1-1',
//                                 component: () => import('../pages/scrm_pc/test1/test1-1.vue'),
//                                 meta: {
//                                     title: '二级菜单1-1'
//                                 },
//                             },
//                             {
//                                 path: 'test1-2',
//                                 component: () => import('../pages/scrm_pc/test1/test1-2.vue'),
//                                 meta: {
//                                     title: '二级菜单1-2'
//                                 },
//                             }
//                         ]
//                 },
//                 {
//                     path: '/test2',
//                     component: () => import('../pages/scrm_pc/test2/index.vue'),
//                     meta: {
//                         title: '一级菜单2'
//                     },
//                     redirect:'/test2/test2-1',
//                         children:[
//                             {
//                                 path: 'test2-1',
//                                 component: () => import('../pages/scrm_pc/test2/test2-1.vue'),
//                                 meta: {
//                                     title: '二级菜单2-1'
//                                 },
//                             },
//                         ]
//                 },
//                 {
//                     // 员工列表
//                     path: '/staffList',
//                     component: () => import('../pages/scrm_pc/staffList.vue'),
//                     meta: {
//                         title: '员工列表'
//                     }
//                 },
//                 {
//                     path: '/test3',
//                     component: () => import('../pages/scrm_pc/test3/index.vue'),
//                     meta: {
//                         title: '一级菜单3'
//                     },
//                     redirect:'/test3/test3-1',     // 该配置是若点击选择一级菜单时，默认选中并跳转到该一级菜单下的第一个二级菜单
//                         children:[
//                             {
//                                 path: 'test3-1',
//                                 component: () => import('../pages/scrm_pc/test3/test3-1.vue'),
//                                 meta: {
//                                     title: '二级菜单4-1'
//                                 },
//                             },
//                             {
//                                 path: 'test3-2',
//                                 component: () => import('../pages/scrm_pc/test3/test3-2.vue'),
//                                 meta: {
//                                     title: '二级菜单3-2'
//                                 },
//                             }
//                         ]
//                 },
//                 {
//                     path: '/test4',
//                     component: () => import('../pages/scrm_pc/test4/index.vue'),
//                     meta: {
//                         title: '一级菜单4'
//                     },
//                     redirect:'/test4/test4-1',     // 该配置是若点击选择一级菜单时，默认选中并跳转到该一级菜单下的第一个二级菜单
//                         children:[
//                             {
//                                 path: 'test4-1',
//                                 component: () => import('../pages/scrm_pc/test4/test4-1.vue'),
//                                 meta: {
//                                     title: '二级菜单4-1'
//                                 },
//                             },
//                             {
//                                 path: 'test4-2',
//                                 component: () => import('../pages/scrm_pc/test4/test4-2.vue'),
//                                 meta: {
//                                     title: '二级菜单4-2'
//                                 },
//                             }
//                         ]
//                 },
//             ]
//         },

//     ]
// })



import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        redirect: '/scrm_pc',
        component: () =>
            import ('../pages/scrm_pc/index'),
    }, {
        path: '/scrm_pc',
        redirect: '/scrm_pc/home',
        // redirect: '/scrm_pc/target/add',
        component: () =>
            import ('../pages/scrm_pc/index'),
        children: [{
                path: 'task',
                redirect: '/scrm_pc/task/taskAllList',
                component: () =>
                    import ('../pages/scrm_pc/task'),
                children: [{
                        path: 'taskAllList',
                        name: 'taskAllList',
                        meta: {
                            title: '全部任务'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/task/taskAllList')
                    }, {
                        path: 'taskList',
                        name: 'taskList',
                        meta: {
                            title: '我新建的任务'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/task/taskList')
                    },
                    {
                        path: 'taskList2',
                        meta: {
                            title: '我待办的任务'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/task/taskList2')
                    },
                    {
                        path: 'taskAdd',
                        meta: {
                            title: '新建任务'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/task/taskAdd')
                    },
                ]
            },
            {
                path: 'order',
                redirect: '/scrm_pc/order/orderList',
                component: () =>
                    import ('../pages/scrm_pc/order'),
                children: [{
                        path: 'orderList',
                        name: 'orderList',
                        meta: {
                            title: '我的合同'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/order/orderMyList')
                    },
                    {
                        path: 'orderAllList',
                        name: 'orderAllList',
                        meta: {
                            title: '订单列表'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/order/orderAllList')
                    },
                    {
                        path: 'orderAdd',
                        meta: {
                            title: '新建订单'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/order/orderAdd')
                    },
                    {
                        path: 'paymentList',
                        meta: {
                            title: '回款列表'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/order/paymentList')
                    },
                    {
                        path: 'paymentListBranch',
                        meta: {
                            title: '部门回款列表'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/order/paymentListBranch')
                    },
                    {
                        path: 'refundList',
                        meta: {
                            title: '退款列表'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/order/refundList')
                    },
                    {
                        path: 'invoiceList',
                        meta: {
                            title: '全部发票'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/order/invoiceList')
                    },
                    {
                        path: 'invoiceSignList',
                        meta: {
                            title: '我领取的发票'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/order/invoiceSignList')
                    },
                    {
                        path: 'invoiceCreaterList',
                        meta: {
                            title: '我创建的发票'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/order/invoiceCreaterList')
                    },
                    {
                        path: 'invoiceAdd',
                        meta: {
                            title: '添加发票'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/order/invoiceAdd')
                    },
                ]
            },
            {
                path: 'client',
                redirect: '/scrm_pc/client/clientMy',
                component: () =>
                    import ('../pages/scrm_pc/client'),
                children: [{
                        path: 'clientList',
                        name: 'clientList',
                        meta: {
                            title: '全部客户'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/client/clientList')
                    },
                    {
                        path: 'clientMy',
                        meta: {
                            title: '我的客户'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/client/clientMy')
                    },
                    {
                        path: 'clientPublic',
                        meta: {
                            title: '公海客户'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/client/clientPublic')
                    },
                    {
                        path: 'linkmanAll',
                        meta: {
                            title: '全部联系人'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/client/linkmanAll')
                    },
                    {
                        path: 'linkmanMy',
                        meta: {
                            title: '我的联系人'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/client/linkmanMy')
                    },
                    {
                        path: 'purchase',
                        meta: {
                            title: '进件订单'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/client/purchase')
                    },
                    {
                        path: 'addPurchase',
                        meta: {
                            title: '添加进件订单'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/client/addPurchase')
                    },
                ]
            },
            {
                path: 'datas',
                redirect: '/scrm_pc/datas/daily',
                component: () =>
                    import ('../pages/scrm_pc/datas'),
                children: [{
                        path: 'daily',
                        meta: {
                            title: '经营日报'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/datas/daily')
                    },
                    {
                        path: 'client',
                        meta: {
                            title: '客户分析'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/datas/client')
                    },
                    {
                        path: 'order',
                        meta: {
                            title: '订单分析'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/datas/order')
                    },
                    {
                        path: 'goal',
                        meta: {
                            title: '目标分析'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/datas/goal')
                    }
                ]
            },
            {
                path:'payroll',
                redirect: '/scrm_pc/payroll/payrollList',
                component: () => import ('../pages/scrm_pc/payroll'),
                children: [
                    {
                        path: 'payrollList',
                        meta: {
                            title: '工资单列表'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/payroll/payrollList')
                    }
                ]
            },
            {
                path: 'target',
                redirect: '/scrm_pc/target/my',
                component: () =>
                    import ('../pages/scrm_pc/target'),
                children: [{
                        path: 'my',
                        meta: {
                            title: '个人目标'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/target/my'),
                    },
                    // {
                    //     path: 'show',
                    //     name: 'show',
                    //     meta: {
                    //         title: '目标展示'
                    //     },
                    //     component: () =>
                    //         import ('../pages/scrm_pc/target/show')
                    // },
                    {
                        path: 'group',
                        meta: {
                            title: '团队目标'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/target/group')
                    },
                    {
                        path: 'add',
                        meta: {
                            title: '新建个人目标'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/target/add')
                    },
                    {
                        path: 'addgroup',
                        meta: {
                            title: '新建团队目标'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/target/addgroup')
                    },

                ]
            },

            {
                path: 'set',
                redirect: '/scrm_pc/set/staffList',
                component: () =>
                    import ('../pages/scrm_pc/set'),
                children: [{
                        path: 'staffList',
                        name: 'staffList',
                        meta: {
                            title: '员工列表'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/set/staffList')
                    },
                    {
                        path: 'set',
                        meta: {
                            title: '客户字段设置'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/set/set')
                    },
                    {
                        path: 'tagSet',
                        meta: {
                            title: '标签设置'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/set/tagSet')
                    },
                    {
                        path: 'orderSet',
                        meta: {
                            title: '订单字段设置'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/set/orderSet')
                    },
                    {
                        path: 'linkmanSet',
                        meta: {
                            title: '联系人字段设置'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/set/linkmanSet')
                    },
                    {
                        path: 'saleSet',
                        meta: {
                            title: '客户跟进设置'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/set/saleSet')
                    },
                    {
                        path: 'roleSet',
                        meta: {
                            title: '角色设置'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/set/roleSet')
                    },
                    {
                        path: 'purchaseSet',
                        meta: {
                            title: '进件设置'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/set/purchaseSet')
                    },
                ]
            },
            {
                path: 'home',
                meta: {
                    title: '首页'
                },
                component: () =>
                    import ('../pages/scrm_pc/home')
            },
            {
                path: 'task/taskDetail',
                meta: {
                    title: '任务详情'
                },
                component: () =>
                    import ('../pages/scrm_pc/task/taskDetail')
            },
            {
                path: 'order/orderDetail',
                meta: {
                    title: '订单详情'
                },
                component: () =>
                    import ('../pages/scrm_pc/order/orderDetail')
            },
            {
                path: 'client/purchaseDetail',
                meta: {
                    title: '订单详情'
                },
                component: () =>
                    import ('../pages/scrm_pc/client/purchaseDetail')
            },
            {
                path: 'target/detail',
                meta: {
                    title: '目标详情'
                },
                component: () =>
                    import ('../pages/scrm_pc/target/detail')
            },
            {
                path: 'target/groupdetail',
                meta: {
                    title: '团体目标详情'
                },
                component: () =>
                    import ('../pages/scrm_pc/target/groupdetail')
            },

        ]
    },

    // {
    //     path: '/',
    //     redirect: '/scrm_pc/home',
    // },



    {
        path: '/scrm_wap',
        redirect: '/scrm_wap/index',
        component: () =>
            import ('../pages/scrm_wap'),
        children: [{
                path: 'index',
                redirect: '/scrm_wap/home',
                // meta: {
                //     title: ''
                // },
                component: () =>
                    import ('../pages/scrm_wap')
            },
            {
                path: 'home',
                // meta: {
                //     title: '首页'
                // },
                component: () =>
                    import ('../pages/scrm_wap/home')
            },
            {
                path: 'menu',
                // meta: {
                //     title: '功能'
                // },
                component: () =>
                    import ('../pages/scrm_wap/menu'),

            },
            // {
            //     path: 'news',
            //     // meta: {
            //     //     title: '消息'
            //     // },
            //     component: () =>
            //         import ('../pages/scrm_wap/news')
            // },
            {
                path: 'set/index',
                // meta: {
                //     title: '设置'
                // },
                component: () =>
                    import ('../pages/scrm_wap/set/index')
            },
        ]
    },
    {
        path: '/scrm_wap/client/clientList',
        meta: {
            title: '全部客户'
        },
        component: () =>
            import ('../pages/scrm_wap/client/clientList')
    },
    {
        path: '/scrm_wap/client/clientMy',
        meta: {
            title: '我的客户'
        },
        component: () =>
            import ('../pages/scrm_wap/client/clientMy')
    },
    {
        path: '/scrm_wap/client/clientPublic',
        meta: {
            title: '公海客户'
        },
        component: () =>
            import ('../pages/scrm_wap/client/clientPublic')
    },
    {
        path: '/scrm_wap/client/addClient',
        meta: {
            title: '添加客户'
        },
        component: () =>
            import ('../pages/scrm_wap/client/addClient')
    },
    {
        path: '/scrm_wap/client/clientDetail',
        meta: {
            title: '客户详情'
        },
        component: () =>
            import ('../pages/scrm_wap/client/clientDetail')
    },
    {
        path: '/scrm_wap/client/addLinkman',
        meta: {
            title: '添加联系人'
        },
        component: () =>
            import ('../pages/scrm_wap/client/addLinkman')
    },
    {
        path: '/scrm_wap/client/linkmanAll',
        meta: {
            title: '全部联系人'
        },
        component: () =>
            import ('../pages/scrm_wap/client/linkmanAll')
    },
    {
        path: '/scrm_wap/client/linkmanMy',
        meta: {
            title: '我的联系人'
        },
        component: () =>
            import ('../pages/scrm_wap/client/linkmanMy')
    },
    {
        path: '/scrm_wap/client/purchase',
        meta: {
            title: '进件订单'
        },
        component: () =>
            import ('../pages/scrm_wap/client/purchase')
    },
    {
        path: '/scrm_wap/client/addPurchase',
        meta: {
            title: '添加进件订单'
        },
        component: () =>
            import ('../pages/scrm_wap/client/addPurchase')
    },
    {
        path: '/scrm_wap/task/taskAllList',
        meta: {
            title: '任务列表'
        },
        component: () =>
            import ('../pages/scrm_wap/task/taskAllList')
    },
    {
        path: '/scrm_wap/task/taskList',
        meta: {
            title: '任务列表'
        },
        component: () =>
            import ('../pages/scrm_wap/task/taskList')
    },
    {
        path: '/scrm_wap/task/taskAdd',
        meta: {
            title: '添加任务'
        },
        component: () =>
            import ('../pages/scrm_wap/task/taskAdd')
    },
    {
        path: '/scrm_wap/task/taskDetail',
        meta: {
            title: '任务详情'
        },
        component: () =>
            import ('../pages/scrm_wap/task/taskDetail')
    },
    {
        path: '/scrm_wap/task/taskList2',
        meta: {
            title: '我的待办'
        },
        component: () =>
            import ('../pages/scrm_wap/task/taskList2')
    },

    {
        path: '/scrm_wap/order/orderList',
        meta: {
            title: '订单列表'
        },
        component: () =>
            import ('../pages/scrm_wap/order/orderList')
    },
    {
        path: '/scrm_wap/order/orderAdd',
        meta: {
            title: '添加订单'
        },
        component: () =>
            import ('../pages/scrm_wap/order/orderAdd')
    },
    {
        path: '/scrm_wap/order/orderDetail',
        meta: {
            title: '订单详情'
        },
        component: () =>
            import ('../pages/scrm_wap/order/orderDetail')
    },
    {
        path: '/scrm_wap/client/purchaseDetail',
        meta: {
            title: '订单详情'
        },
        component: () =>
            import ('../pages/scrm_wap/client/purchaseDetail')
    },
    { 
        path: '/scrm_wap/order/paymentList',
        meta: {
            title: '回款列表'
        },
        component: () =>
            import ('../pages/scrm_wap/order/paymentList')
    },
    {
        path: '/scrm_wap/order/paymentListBranch',
        meta: {
            title: '回款列表'
        },
        component: () =>
            import ('../pages/scrm_wap/order/paymentListBranch')
    },
    {
        path: '/scrm_wap/order/refundList',
        meta: {
            title: '退款列表'
        },
        component: () =>
            import ('../pages/scrm_wap/order/refundList')
    },
    {
        path: '/scrm_wap/order/orderAllList',
        meta: {
            title: '全部合同'
        },
        component: () =>
            import ('../pages/scrm_wap/order/orderAllList')
    },
    {
        path: '/scrm_wap/order/invoiceList',
        meta: {
            title: '全部发票'
        },
        component: () =>
            import ('../pages/scrm_wap/order/invoiceList')
    },
    {
        path: '/scrm_wap/order/invoiceSignList',
        meta: {
            title: '我领取的发票'
        },
        component: () =>
            import ('../pages/scrm_wap/order/invoiceSignList')
    },
    {
        path: '/scrm_wap/order/invoiceCreaterList',
        meta: {
            title: '我创建的发票'
        },
        component: () =>
            import ('../pages/scrm_wap/order/invoiceCreaterList')
    },
    {
        path: '/scrm_wap/order/invoiceAdd',
        meta: {
            title: '添加发票'
        },
        component: () =>
            import ('../pages/scrm_wap/order/invoiceAdd')
    },


    {
        path: '/scrm_wap/datas/daily',
        meta: {
            title: '经营日报'
        },
        component: () =>
            import ('../pages/scrm_wap/datas/daily')
    },
    {
        path: '/scrm_wap/datas/client',
        meta: {
            title: '客户分析'
        },
        component: () =>
            import ('../pages/scrm_wap/datas/client')
    },
    {
        path: '/scrm_wap/datas/order',
        meta: {
            title: '订单分析'
        },
        component: () =>
            import ('../pages/scrm_wap/datas/order')
    },
    {
        path: '/scrm_wap/datas/goal',
        meta: {
            title: '目标分析'
        },
        component: () =>
            import ('../pages/scrm_wap/datas/goal')
    },
    {
        path: '/scrm_wap/target/my',
        meta: {
            title: '我的目标'
        },
        component: () =>
            import ('../pages/scrm_wap/target/my'),

    },
    {
        path: '/scrm_wap/target/group',
        meta: {
            title: '团队目标'
        },
        component: () =>
            import ('../pages/scrm_wap/target/group')
    },



    {
        path: '/scrm_wap/set/staffList',
        meta: {
            title: '员工列表'
        },
        component: () =>
            import ('../pages/scrm_wap/set/staffList')
    },
    {
        path: '/scrm_wap/payroll/payrollList',
        meta: {
            title: '工资单列表'
        },
        component: () =>
            import ('../pages/scrm_wap/payroll/payrollList')
    },

]

const router = new VueRouter({
    mode: "history",
    routes
})
router.beforeEach((to, from, next) => {
    // console.log(to, from, next);
    // to: Route: 即将要进入的目标 路由对象

    // from: Route: 当前导航正要离开的路由

    // next: Function: 一定要调用该方法来 resolve 这个钩子。执行效果依赖 next 方法的调用参数。

    // const nextRoute = ['temp', 'editTemplate', 'plugin', 'toolList', 'templateList', 'template', 'workflow', 'workflowList', 'sysmanage', 'addWorkflow'];

    // let isLogin = true;

    // 未登录状态；当路由到nextRoute指定页时，跳转至login

    // if (nextRoute.indexOf(to.name) >= 0 && !isLogin) {
    //     next({
    //         path: '/login',
    //         // 将跳转的路由path作为参数，登录成功后跳转到该路由
    //         query: {
    //             redirect: to.fullPath
    //         }
    //     })

    // } else {
    //     next();

    // }

    // 已登录状态；当路由到login时，跳转至home

    // if (to.name === 'login') {
    //     if (isLogin) {
    //         console.log('已登录');

    //         router.push({
    //             path: '/temp/index'
    //         });

    //     }

    // }
    let path = routes.find((item) => {
        let url = to.path.split('/')[1];
        if (item.path.indexOf(url) != -1) {
            return true
        }
    })

    if (path) {
        next();
    } else {
        router.push({
            path: '/scrm_pc'
        });
    }


});
export default router